import { request } from "utils";

export async function callUpdateNotification(id, type) {
  const res = await request.post('notification/update', {
    id, type
  });
  return res;
}

export default callUpdateNotification;
