import React from "react";
import { connect } from "react-redux";
import UserOutlined from "@ant-design/icons/UserOutlined";
import { Avatar as AntdAvatar } from "antd";
import { getAvatarURL } from 'utils';


type AvatarProps = {
  userInfo: any,
  avatarVersion: Number,
};


function Avatar({ userInfo, avatarVersion, ...rest }: AvatarProps) {
  const userName = userInfo && userInfo.name ? userInfo.name : '';
  const splitName = (userName || "").trim().split(' ');
  const name = splitName[splitName.length - 1];
  const firstChar = name.charAt(0).toUpperCase();
  return (
    <AntdAvatar
      size="large"
      // icon={<UserOutlined />}
      src={getAvatarURL(userInfo, avatarVersion)}
      {...rest}
    >
      {firstChar}
    </AntdAvatar>
  );
}
export default connect(({ app: { avatarVersion } }) => ({
  avatarVersion
}))(Avatar)