import { sleep, request } from "utils";

export type MenuInfoType = {};

export type MenuListType = MenuInfoType[];

const url = "/menu/list";

export async function callGetMenus(params: any = {}): Promise<MenuListType> {
  const res = await request.get(url, params);
  return res;
}

export default callGetMenus;
