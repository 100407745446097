import React from 'react';
import { Drawer, Badge, Button, notification } from 'antd';
import moment from 'moment';
import BellFilled from '@ant-design/icons/BellFilled';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { usePrevious } from 'utils/hooks';
import callGetNotificationList from 'api/callGetNotificationList';
import NotificationList from './NotificationList';

import "./styles.scss";

type NotificationProps = {};

async function fetchUnreadCount() {
  try {
    const res = await callGetNotificationList({ type: 'unread' });
    return res.total;
  } catch (e) {
    return 0;
  }
};

let blockNoti = true;

function Notification(props: NotificationProps) {
  const [visible, setVisible] = React.useState(false);
  const [unreadCount, setUnreadCount] = React.useState(0);
  const [showNewNotiBtn, setShowNewNotiBtn] = React.useState(false);

  const prevUnreadCount = usePrevious(unreadCount, 0);

  React.useEffect(() => {
    function getUnreadCount() {
      fetchUnreadCount().then((count) => setUnreadCount(count));
    }
    getUnreadCount();

    const interval = setInterval(() => {
      getUnreadCount();
      blockNoti = false;
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [setUnreadCount]);

  React.useEffect(() => {
    if (unreadCount > prevUnreadCount) {
      if (visible) {
        setShowNewNotiBtn(true);
      } else if (!blockNoti) {
        notification.info({
          message: "Thông báo",
          description: `Có ${unreadCount - prevUnreadCount} thông báo mới.`,
          onClick: () => {
            notification.destroy();
            _onOpen();
          }
        });
      }
    }
  }, [unreadCount, prevUnreadCount, visible, setShowNewNotiBtn])

  function _onClose() {
    setVisible(false);
  }

  function _onOpen() {
    setVisible(true);
    fetchUnreadCount().then((count) => setUnreadCount(count));
  }

  function _onItemClick(forceCloseDrawer) {
    fetchUnreadCount().then((count) => setUnreadCount(count));
    if (forceCloseDrawer) {
      _onClose();
    }
  }

  function _onNewNotiClick() {
    setShowNewNotiBtn(false);
  }

  return (
    <div className="notification d-inline-block mr-4">
      <a href="javascript: void(0);" onClick={_onOpen}>
        <Badge overflowCount={9} count={unreadCount}>
          <BellFilled style={styles.icon} />
        </Badge>
      </a>
      <Drawer
        destroyOnClose
        title={<div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <span>Thông báo <Badge count={unreadCount} overflowCount={1000} /></span>
          </div>
          <div className="col-auto">
            <Button onClick={_onClose} type='text' icon={<CloseOutlined />} />
          </div>
        </div>}
        placement="right"
        closable={false}
        onClose={_onClose}
        visible={visible}
        width={500}
      >
        <div className={`noti-drawer${showNewNotiBtn ? " show-new-not-btn" : ""}`}>
          <NotificationList onItemClick={_onItemClick} onNewNotiClick={_onNewNotiClick} />
        </div>
      </Drawer>
    </div>
  );
}

const styles = {
  icon: {
    fontSize: 25,
    color: '#e4e9f0',
  }
}
export default Notification;