global.env = process.env.NODE_ENV;
if (global.env === "production") {
  console.log = () => { };
  console.error = () => { };
  console.warn = () => { };
}
console.log("global.env", global.env);
export const API_DOMAIN =
  global.env === "production" ? "" : "http://localhost:6080";
export const API_URL = `${API_DOMAIN}/api/v1`;
