import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'ducks/router';
import callUpdateNotification from 'api/callUpdateNotification';
import moment from '../../../../../node_modules/moment/moment';

type NotificationItemProps = {
  item: any,
  onClick: (item: any) => void,
};


function NotificationItem({ item, onClick, dispatch }: NotificationItemProps) {
  const [sItem, setSItem] = useState(null);
  useEffect(() => {
    setSItem(item);
  }, [item]);

  const it = sItem || item;

  async function _onClick() {
    try {
      await callUpdateNotification(it.id, 'read');
      setSItem((prevSItem) => ({
        ...prevSItem,
        is_read: 1,
      }));
      let forceCloseDrawer = false;
      if (it.request_id) {
        dispatch(push(`/request/view/${it.request_id}`));
        forceCloseDrawer = true;
      }
      onClick && onClick(forceCloseDrawer);
    } catch (e) { }
  }

  return (
    <div className={`row align-items-center notification-item ${!it.is_done || !it.is_read ? 'unread' : ''}`} onClick={_onClick}>
      <div className="col">
        <div>{it.content}</div>
        <div className="humanized-time">
          <i>{moment.duration(moment().diff(moment(it.created_at))).humanize()} trước</i>
        </div>
      </div>
      {!!it.is_done ?
        (
          <div className="col-auto text-success">
            <i><span className="icmn-checkmark" /> Đã hoàn thành</i>
          </div>
        )
        :
        (
          <div className="col-auto text-danger">
            <i>Chưa hoàn thành</i>
          </div>
        )
      }
    </div>
  );
}

export default connect()(NotificationItem)
