import { Component } from "react";
import { connect } from "react-redux";


export class CheckRoleElement extends Component {
  render() {
    const { children, route, checkedRolesData, defaultChildren } = this.props;

    if (!route || checkedRolesData[route]) {
      return children;
    }
    return defaultChildren || null;
  }
}

export default connect(({ menu: { checkedRolesData } }) => ({
  checkedRolesData,
}))(CheckRoleElement);
