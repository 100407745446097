import { sleep, request } from "utils";

export type ShopInfoType = {
  id: string,
  name: string,
  alias: string,
  address: string,
  logo: string,
  phone: string,
  tax_identification: string,
  email: string,
};

export async function callGetShopDetail(): Promise<ShopInfoType> {
  const res = await request.get("/shop/detail");
  return res;
}

export default callGetShopDetail;
