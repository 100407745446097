import { createAction, createReducer } from "redux-act";
import { push, replace } from "connected-react-router";
import { pendingTask, begin, end } from "react-redux-spinner";
import axios from "axios";
import moment from "moment";
import callLogin from "api/callLogin";
import callGetUserInfo from "api/callGetUserInfo";
import callGetShopDetail from "api/Shop/callGetShopDetail";
import {
  loadMenuData,
  _setMenuData,
  _setCheckedRolesData,
  _setRoleMenuData,
  _setMenuLoaded,
} from "./menu";
import { checkArrayInArray } from "utils/index";

const REDUCER = "app";
const NS = `@@${REDUCER}/`;

const _setFrom = createAction(`${NS}SET_FROM`);
const _setLoading = createAction(`${NS}SET_LOADING`);
const _setHideLogin = createAction(`${NS}SET_HIDE_LOGIN`);

export const setUserState = createAction(`${NS}SET_USER_STATE`);
export const setAvatarVersion = createAction(`${NS}SET_AVATAR_VERSION`);
export const setUpdatingContent = createAction(`${NS}SET_UPDATING_CONTENT`);
export const setActiveDialog = createAction(`${NS}SET_ACTIVE_DIALOG`);
export const deleteDialogForm = createAction(`${NS}DELETE_DIALOG_FORM`);
export const addSubmitForm = createAction(`${NS}ADD_SUBMIT_FORM`);
export const deleteSubmitForm = createAction(`${NS}DELETE_SUBMIT_FORM`);
export const setLayoutState = createAction(`${NS}SET_LAYOUT_STATE`);
export const setShopDetail = createAction(`${NS}SET_SHOP_DETAIL`);

const clearToken = () => {
  window.localStorage.setItem("app.Authorization", "");
  axios.defaults.headers.common["Authorization"] = "";
};

const setToken = (token, isSaveToLocal = true) => {
  isSaveToLocal && window.localStorage.setItem("app.Authorization", token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const setLoading = (isLoading) => {
  const action = _setLoading(isLoading);
  action[pendingTask] = isLoading ? begin : end;
  return action;
};

export const resetHideLogin = () => (dispatch, getState) => {
  const state = getState();
  if (state.pendingTasks === 0 && state.app.isHideLogin) {
    dispatch(_setHideLogin(false));
  }
  return Promise.resolve();
};

export const getUserInfo = () => async (dispatch, getState) => {
  const state = getState();

  let userInfo = null;

  if (!state.app.userState) {
    userInfo = await callGetUserInfo();
    if (!userInfo) {
      throw new Error("Get user info failed");
    }
    dispatch(
      setUserState({
        userState: {
          ...userInfo,
        },
      })
    );
  } else {
    userInfo = state.app.userState;
  }
  return userInfo;
};

export const getShopDetail = () => async (dispatch, getState) => {
  const state = getState();

  let shopDetail = null;

  if (!state.app.shopDetail) {
    shopDetail = await callGetShopDetail();
    if (!shopDetail) {
      throw new Error("Get shop info failed");
    }
    dispatch(
      setShopDetail({
        ...shopDetail,
      })
    );
  } else {
    shopDetail = state.app.shopDetail;
  }
  return shopDetail;
};

export const initAuth = (roles, match) => async (dispatch, getState) => {
  // Use Axios there to get User Data by Auth Token with Bearer Method Authentication
  const state = getState();
  console.log("location", state.router.location);

  try {
    const token = window.localStorage.getItem("app.Authorization");
    setToken(token, false);
    if (!token) {
      throw new Error("No token");
    }

    const userInfo = await dispatch(getUserInfo());
    await dispatch(getShopDetail());

    const [_, roleMenuData] = await dispatch(loadMenuData());

    if (!roleMenuData) {
      throw new Error("Load Menu Failed");
    }

    if (!match) {
      dispatch(push("/notfound"));
      return false;
    }

    console.log("match", match);
    const currMenu = roleMenuData.find((menu) => menu.url === match.path);

    let defaultRoute = null;
    if (currMenu && currMenu.parent) {
      defaultRoute = currMenu.parent.url;
    } else if (roleMenuData.length > 0) {
      for (let i = 0; i < roleMenuData.length; i++) {
        const menu = roleMenuData[i];
        if (
          menu.url &&
          (
            (
              Array.isArray(menu.roles) &&
              checkArrayInArray(userInfo.role, menu.roles)
            ) ||
            !menu.roles
          )
        ) {
          defaultRoute = menu.url;
          break;
        }
      }
    }

    if (
      currMenu &&
      (
        !currMenu.roles ||
        (
          Array.isArray(currMenu.roles) &&
          checkArrayInArray(userInfo.role, currMenu.roles)
        )
      )
    ) {
      return true;
    }

    if (!(state.router.location.pathname === defaultRoute)) {
      if (!defaultRoute) {
        dispatch(replace("/notfound"));
      } else {
        dispatch(replace(defaultRoute));
      }
    }
    return false;
  } catch (e) {
    console.log(e);
    const location = state.router.location;
    const from = location.pathname + location.search;
    dispatch(_setFrom(from));
    clearToken();
    dispatch(push("/login"));
    throw e;
  }
};

export async function login(username, password, dispatch) {
  // Use Axios there to get User Auth Token with Basic Method Authentication

  try {
    const logInRes = await callLogin({ password, identification: username });

    if (logInRes) {
      setToken(logInRes.token);
      await dispatch(getUserInfo());
      dispatch(_setHideLogin(true));
      dispatch(push("/"));
      return true;
    } else {
      throw new Error("Login failed");
    }
  } catch (e) {
    console.log(e);
    clearToken();
    dispatch(push("/login"));
    dispatch(_setFrom(""));
    return false;
  }
}

export const logout = () => (dispatch, getState) => {
  dispatch(
    setUserState({
      userState: null,
    })
  );
  dispatch(_setMenuData([]));
  dispatch(_setRoleMenuData([]));
  dispatch(_setCheckedRolesData({}));
  dispatch(_setMenuLoaded(false));
  dispatch(setShopDetail(null));
  clearToken();
  dispatch(push("/login"));
};

const initialState = {
  // APP STATE
  from: "",
  isUpdatingContent: false,
  isLoading: false,
  activeDialog: "",
  dialogForms: {},
  submitForms: {},
  isHideLogin: false,
  avatarVersion: 0,

  // LAYOUT STATE
  layoutState: {
    isMenuTop: false,
    menuMobileOpened: false,
    menuCollapsed: false,
    menuShadow: true,
    themeLight: false,
    squaredBorders: true,
    borderLess: true,
    fixedWidth: false,
    settingsOpened: false,
  },

  // USER STATE
  userState: null,
  shopDetail: null,
  appRoles: [
    {
      name: "NVX",
      key: "NVX",
    },
    {
      name: "NVX (Chỉ xem)",
      key: "NVX_R",
    },
    {
      name: "DVX",
      key: "DVX",
    },
    {
      name: "DVX (Chỉ xem)",
      key: "DVX_R",
    },
    {
      name: "KTX",
      key: "KTX",
    },
    {
      name: "KTX (Chỉ xem)",
      key: "KTX_R",
    },
  ],
};

export default createReducer(
  {
    [_setFrom]: (state, from) => ({ ...state, from }),
    [_setLoading]: (state, isLoading) => ({ ...state, isLoading }),
    [_setHideLogin]: (state, isHideLogin) => ({ ...state, isHideLogin }),
    [setUpdatingContent]: (state, isUpdatingContent) => ({
      ...state,
      isUpdatingContent,
    }),
    [setAvatarVersion]: (state) => ({ ...state, avatarVersion: moment().unix() }),
    [setUserState]: (state, { userState }) => ({ ...state, userState }),
    [setLayoutState]: (state, param) => {
      const layoutState = { ...state.layoutState, ...param, themeLight: false };
      const newState = { ...state, layoutState };
      window.localStorage.setItem(
        "app.layoutState",
        JSON.stringify(newState.layoutState)
      );
      return newState;
    },
    [setActiveDialog]: (state, activeDialog) => {
      const result = { ...state, activeDialog };
      if (activeDialog !== "") {
        const id = activeDialog;
        result.dialogForms = { ...state.dialogForms, [id]: true };
      }
      return result;
    },
    [deleteDialogForm]: (state, id) => {
      const dialogForms = { ...state.dialogForms };
      delete dialogForms[id];
      return { ...state, dialogForms };
    },
    [addSubmitForm]: (state, id) => {
      const submitForms = { ...state.submitForms, [id]: true };
      return { ...state, submitForms };
    },
    [setShopDetail]: (state, shopDetail) => {
      return { ...state, shopDetail };
    },
    [deleteSubmitForm]: (state, id) => {
      const submitForms = { ...state.submitForms };
      delete submitForms[id];
      return { ...state, submitForms };
    },
  },
  initialState
);
