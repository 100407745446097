import { request } from "utils";

type LoginInfoType = {
  identification: String,
  password: String,
};

export async function callLogin(
  { identification, password }: LoginInfoType,
  isQuiet: Boolean = false
) {
  const res = await request.post(
    "/auth/login",
    {
      identification,
      password,
    },
    isQuiet
  );
  return res;
}

export default callLogin;
