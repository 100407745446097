import React from "react";
import { Button } from "antd";
import "./style.scss";

class AppFooter extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="footer__top"></div>
        <div className="footer__bottom"></div>
      </div>
    );
  }
}

export default AppFooter;
