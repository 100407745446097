import React from "react";
import { Route } from "react-router-dom";
import { ConnectedSwitch } from "reactRouterConnected";
import Loadable from "react-loadable";
import Page from "components/LayoutComponents/Page";
import NotFoundPage from "pages/DefaultPages/NotFoundPage";
import HomePage from "pages/DefaultPages/HomePage";

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => null,
  });

const genRoute = (rootPath, component, detailComponent, defaultProps = {}) => {
  const routes = {
    [`${rootPath}`]: {
      exact: true,
      component,
    },
    [`${rootPath}/view/:id`]: {
      component: detailComponent || component,
      defaultProps: {
        ...(defaultProps["view"] || {}),
        mode: "view",
      },
    },
    [`${rootPath}/edit/:id`]: {
      component: detailComponent || component,
      defaultProps: {
        ...(defaultProps["edit"] || {}),
        mode: "edit",
      },
    },
    [`${rootPath}/create`]: {
      component: detailComponent || component,
      exact: true,
      defaultProps: {
        ...(defaultProps["create"] || {}),
        mode: "create",
      },
    },
  };
  return routes;
};

const loadableRoutes = {
  // Default Pages
  "/login": {
    component: loadable(() => import("pages/DefaultPages/LoginPage")),
    // noRoleRoute: true,
  },
  "/company": {
    exact: true,
    component: loadable(() => import("pages/CompanyPage")),
    defaultProps: {
      mode: "view",
    },
  },
  "/company/edit": {
    exact: true,
    component: loadable(() => import("pages/CompanyPage")),
    defaultProps: {
      mode: "edit",
    },
  },
  ...genRoute(
    "/employee",
    loadable(() => import("pages/EmployeesPage")),
    loadable(() => import("pages/EmployeeDetailPage"))
  ),
  ...genRoute(
    "/nocustomer",
    loadable(() => import("pages/NoCustomersPage"))
  ),
  ...genRoute(
    "/price-quotation",
    loadable(() => import("pages/PriceQuotationListPage"))
  ),
  ...genRoute(
    "/price-quotation/form",
    loadable(() => import("pages/PriceQuotationTemplateListPage"))
  ),
  ...genRoute(
    "/contract",
    loadable(() => import("pages/ContractListPage"))
  ),
  ...genRoute(
    "/contract/form",
    loadable(() => import("pages/ContractTemplateListPage"))
  ),
  ...genRoute(
    "/request",
    loadable(() => import("pages/RequestListPage"))
  ),
  ...genRoute(
    "/officialdispatch",
    loadable(() => import("pages/OfficialDispatchListPage"))
  ),
  ...genRoute(
    "/advance",
    loadable(() => import("pages/AdvanceListPage"))
  ),
  ...genRoute(
    "/dvx-ip",
    loadable(() => import("pages/BrandListPage"))
  ),
  ...genRoute(
    "/nvx-expired-ip",
    loadable(() => import("pages/ExpiredBrandListPage"))
  ),
  ...genRoute(
    "/nvx-ip",
    loadable(() => import("pages/BrandListPage"))
  ),
  // ...genRoute(
  //   "/nvx-potential-ip",
  //   loadable(() => import("pages/PotentialBrandListPage"))
  // ),
  ...genRoute(
    "/dvx-bq",
    loadable(() => import("pages/LicenseListPage"))
  ),
  ...genRoute(
    "/nvx-bq",
    loadable(() => import("pages/LicenseListPage"))
  ),
  ...genRoute(
    "/customer",
    loadable(() => import("pages/TrueCustomersPage"))
  ),
  ...genRoute(
    "/receipt",
    loadable(() => import("pages/ReceipstListPage"))
  ),
  ...genRoute(
    "/report",
    loadable(() => import("pages/ReportListPage"))
  ),
  ...genRoute(
    "/gd",
    loadable(() => import("pages/DocumentListPage/GD"))
  ),
  ...genRoute(
    "/msmv",
    loadable(() => import("pages/DocumentListPage/MSMV"))
  ),
  ...genRoute(
    "/hdgd",
    loadable(() => import("pages/DocumentListPage/HDGD"))
  ),
  ...genRoute(
    "/bbtlhd",
    loadable(() => import("pages/DocumentListPage/BBTLHD"))
  ),
  ...genRoute(
    "/ksnh",
    loadable(() => import("pages/DocumentListPage/KSNH"))
  ),
  ...genRoute(
    "/hddvpl",
    loadable(() => import("pages/DocumentListPage/HDDVPL"))
  ),
  ...genRoute(
    "/dkkd",
    loadable(() => import("pages/DocumentListPage/DKKD"))
  ),
  ...genRoute(
    "/vb",
    loadable(() => import("pages/DocumentListPage/VB"))
  ),
  ...genRoute(
    "/score",
    loadable(() => import("pages/EmployeesScorePage"))
  ),
  ...genRoute(
    "/ktx-advance",
    loadable(() => import("pages/AdvanceListPage"))
  ),
  ...genRoute(
    "/news",
    loadable(() => import("pages/NewsListPage"))
  ),
  "/profile/view/:id": {
    component: loadable(() => import("pages/EmployeeDetailPage")),
    defaultProps: {
      mode: "view",
    },
  },
  "/profile/edit/:id": {
    component: loadable(() => import("pages/EmployeeDetailPage")),
    defaultProps: {
      mode: "edit",
    },
  },
  "/dashboard": {
    component: loadable(() => import("pages/DashboardPage")),
  },
};
class Routes extends React.Component {
  timeoutId = null;

  componentDidMount() {
    this.timeoutId = setTimeout(
      () =>
        Object.keys(loadableRoutes).forEach((path) =>
          loadableRoutes[path].component.preload()
        ),
      5000 // load after 5 sec
    );
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  render() {
    return (
      <ConnectedSwitch>
        <Route exact path="/" component={HomePage} />
        {Object.keys(loadableRoutes).map((path) => {
          const { exact, defaultProps, component, ...props } = loadableRoutes[
            path
          ];
          props.exact = exact === void 0 || exact || false; // set true as default
          return (
            <Route
              key={path}
              path={path}
              {...props}
              render={(newProps) =>
                React.createElement(component, {
                  ...defaultProps,
                  ...newProps,
                  path,
                })
              }
            />
          );
        })}
        <Route
          render={() => (
            <Page>
              <NotFoundPage />
            </Page>
          )}
        />
      </ConnectedSwitch>
    );
  }
}

export { loadableRoutes };
export default Routes;
