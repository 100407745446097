import React from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import { reduce } from "lodash";
import { setLayoutState } from "ducks/app";
import { default as menuData } from "./menuData";
import "./style.scss";

const SubMenu = Menu.SubMenu;
const Divider = Menu.Divider;

const mapStateToProps = ({ app, router, menu }, props) => {
  const { layoutState } = app;
  return {
    pathname: router.location.pathname,
    collapsed: layoutState.menuCollapsed,
    theme: layoutState.themeLight ? "light" : "dark",
    settingsOpened: layoutState.settingsOpened,
    menuData: menu.menuData,
    menuLoaded: menu.menuLoaded,
  };
};

@connect(mapStateToProps)
@withRouter
class MenuTop extends React.Component {
  state = {
    pathname: this.props.pathname,
    collapsed: this.props.collapsed,
    theme: this.props.theme,
    selectedKeys: "",
    openKeys: [""],
    settingsOpened: this.props.settingsOpened,
  };

  handleClick = (e) => {
    const { dispatch, isMobile } = this.props;
    if (isMobile) {
      // collapse menu on isMobile state
      dispatch(setLayoutState({ menuMobileOpened: false }));
    }
    if (e.key === "settings") {
      // prevent click and toggle settings block on theme settings link
      dispatch(setLayoutState({ settingsOpened: !this.state.settingsOpened }));
      return;
    }
    // set current selected keys
    this.setState({
      selectedKeys: e.key,
      openKeys: e.keyPath,
    });
  };

  onOpenChange = (openKeys) => {
    this.setState({
      openKeys,
    });
  };

  getPath(data, id, parents = []) {
    const { selectedKeys } = this.state;
    let items = reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result;
        } else if (entry.url === id && selectedKeys === "") {
          return [entry].concat(parents);
        } else if (entry.key === id && selectedKeys !== "") {
          return [entry].concat(parents);
        } else if (entry.children) {
          let nested = this.getPath(
            entry.children,
            id,
            [entry].concat(parents)
          );
          return nested ? nested : result;
        }
        return result;
      },
      []
    );
    return items.length > 0 ? items : false;
  }

  getActiveMenuItem = (props, items) => {
    this.setState((prevState) => {
      const { selectedKeys, pathname } = prevState;
      let { collapsed } = props;
      let [activeMenuItem, ...path] =
        this.getPath(items, !selectedKeys ? pathname : selectedKeys) || [];

      if (collapsed) {
        path = [""];
      }

      return {
        selectedKeys: activeMenuItem ? activeMenuItem.key : "",
        openKeys: activeMenuItem ? path.map((entry) => entry.key) : [],
        collapsed,
      };
    });
  };

  generateMenuPartitions(items) {
    console.log("menu menu menu", items);
    return items.map((menuItem) => {
      if (menuItem.children) {
        let subMenuTitle = (
          <span className="menuTop__title-wrap" key={menuItem.key}>
            <span className="menuTop__item-title">{menuItem.title}</span>
            {menuItem.icon && (
              <span className={menuItem.icon + " menuTop__icon"} />
            )}
          </span>
        );
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {this.generateMenuPartitions(menuItem.children)}
          </SubMenu>
        );
      }
      return this.generateMenuItem(menuItem);
    });
  }

  generateMenuItem(item) {
    const { key, title, url, icon, disabled } = item;
    const { dispatch } = this.props;
    return item.divider ? (
      <Divider key={Math.random()} />
    ) : item.url ? (
      <Menu.Item key={key} disabled={disabled}>
        <Link
          to={url}
          onClick={
            this.props.isMobile
              ? () => {
                  dispatch(setLayoutState({ menuCollapsed: false }));
                }
              : undefined
          }
        >
          <span className="menuTop__item-title">{title}</span>
          {icon && <span className={icon + " menuTop__icon"} />}
        </Link>
      </Menu.Item>
    ) : (
      <Menu.Item key={key} disabled={disabled}>
        <span className="menuTop__item-title">{title}</span>
        {icon && <span className={icon + " menuTop__icon"} />}
      </Menu.Item>
    );
  }

  componentDidMount() {
    this.getActiveMenuItem(this.props, this.props.menuData);
  }

  static getDerivedStateFromProps(props) {
    return {
      pathname: props.pathname,
      theme: props.theme,
      settingsOpened: props.settingsOpened,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { collapsed, menuLoaded } = this.props;
    const { pathname } = this.state;
    if (
      !this.props.isMobile &&
      (collapsed !== prevProps.collapsed ||
        menuLoaded !== prevProps.menuLoaded ||
        pathname !== prevState.pathname)
    ) {
      this.getActiveMenuItem(this.props, this.props.menuData);
    }
  }

  render() {
    const { selectedKeys, openKeys, theme } = this.state;
    const menuItems = this.generateMenuPartitions(this.props.menuData);
    return (
      <div className="menuTop">
        <div className="menuTop__logo">
          <div className="menuTop__logoContainer">
            <img src="/resources/images/logo-inverse.jpg" alt="" />
          </div>
        </div>
        <Menu
          theme={theme}
          onClick={this.handleClick}
          selectedKeys={[selectedKeys]}
          openKeys={openKeys}
          onOpenChange={this.onOpenChange}
          mode="horizontal"
          className="menuTop__navigation"
        >
          {menuItems}
        </Menu>
      </div>
    );
  }
}

export { MenuTop, menuData };
