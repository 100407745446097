// @flow
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { reduce } from "lodash";
import { Breadcrumb as AntdBreadcrumb } from "antd";
import "./style.scss";

const getPath = (data, url, parents = []) => {
  let items = reduce(
    data,
    (result, entry) => {
      if (result.length) {
        return result;
      } else if (entry.url === url) {
        return [entry].concat(parents);
      } else if (entry.children) {
        let nested = getPath(entry.children, url, [entry].concat(parents));
        return nested ? nested : result;
      }
      return result;
    },
    []
  );
  return items.length > 0 ? items : false;
};

const getBreadcrumb = (props, state, items) => {
  let { breadcrumb } = state;
  let url = props.location.pathname;
  let [activeMenuItem, ...path] = getPath(items, url) || [];

  if (activeMenuItem && path.length) {
    breadcrumb = path.reverse().map((item, index) => {
      if (index === path.length - 1) {
        return (
          <AntdBreadcrumb.Item key={item.key}>
            <strong>{activeMenuItem.title}</strong>
          </AntdBreadcrumb.Item>
        );
      } else {
        return (
          <AntdBreadcrumb.Item key={item.key}>
            <span className="text-muted">{item.title}</span>
          </AntdBreadcrumb.Item>
        );
      }
    });
  } else {
    breadcrumb = (
      <AntdBreadcrumb.Item>
        <strong>{props.name}</strong>
      </AntdBreadcrumb.Item>
    );
  }

  // if (activeMenuItem && path.length) {
  //   breadcrumb = path.reverse().map((item, index) => {
  //     if (index === path.length - 1) {
  //       return (
  //         <span key={item.key}>
  //           <span className="breadcrumbBar__arrow text-muted" />
  //           <span className="text-muted">{item.title}</span>
  //           <span className="breadcrumbBar__arrow" />
  //           <strong>{activeMenuItem.title}</strong>
  //         </span>
  //       );
  //     } else {
  //       return (
  //         <span key={item.key}>
  //           <span className="breadcrumbBar__arrow text-muted" />
  //           <span className="text-muted">{item.title}</span>
  //         </span>
  //       );
  //     }
  //   });
  // } else {
  //   breadcrumb = (
  //     <span>
  //       <span className="breadcrumbBar__arrow" />
  //       <strong>{props.name}</strong>
  //     </span>
  //   );
  // }
  return breadcrumb;
};

const mapStateToProps = ({ menu }) => {
  return {
    menuData: menu.menuData,
  };
};

@connect(mapStateToProps)
@withRouter
class Breadcrumb extends React.Component {
  state = {
    breadcrumb: [],
  };

  getPath(data, url, parents = []) {
    return getPath(data, url, parents);
  }

  getBreadcrumb = (props, items) => {
    return getBreadcrumb(props, this.state, items);
  };

  static getDerivedStateFromProps(props) {
    const newState = {
      breadcrumb: getBreadcrumb(props, props.menuData),
    };
    return newState;
  }

  componentDidMount() {
    this.setState({
      breadcrumb: this.getBreadcrumb(this.props, this.props.menuData),
    });
  }

  render() {
    let { breadcrumb } = this.state;
    return (
      <div className="breadcrumbBar">
        <AntdBreadcrumb>
          <AntdBreadcrumb.Item>
            <Link to={`/`} className="text-muted">
              Home
            </Link>
          </AntdBreadcrumb.Item>
          {breadcrumb}
        </AntdBreadcrumb>
      </div>
    );
  }
}

export default Breadcrumb;
