import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import "./styles.scss";

const CountDown = (props) => {
    const [remainTime, setRemainTime] = useState(0);
    const [status, setStatus] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const now = moment();
            const start = moment(`${now.format('YYYY-MM-DD')} 08:00:00`);
            const end = moment(`${now.format('YYYY-MM-DD')} 17:00:00`);
            const remainDiff = end.diff(now);
            if (remainDiff < 0) {
                setRemainTime('Hết giờ làm việc');
                setStatus('');
            } else if (remainDiff > end.diff(start)) {
                setRemainTime('Chưa đến giờ làm việc');
                setStatus('');
            } else {
                const remainDiffDuration = moment.duration(remainDiff);
                let [hours, minutes, seconds] = [
                    Math.floor(remainDiffDuration.asHours()),
                    remainDiffDuration.minutes(),
                    remainDiffDuration.seconds()
                ];
                const hoursStr = hours < 10 ? `0${hours}` : hours;
                const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
                const secondsStr = seconds < 10 ? `0${seconds}` : seconds;

                const remainDiffStr = [hoursStr, minutesStr, secondsStr].join(':');
                setRemainTime(remainDiffStr);
                 
                console.log("minutes: ", minutes)
                if (hours === 0 && minutes < 30) {
                    setStatus('alert');
                } else {
                    setStatus('');
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <span className={`count-down ${status}`}>
            {remainTime}
        </span>
    );
}

export default CountDown;