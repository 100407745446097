import React from "react";
import { connect } from "react-redux";
import { Menu, Layout } from "antd";
import { Link, withRouter } from "react-router-dom";
import { reduce } from "lodash";
import { setLayoutState } from "ducks/app";
import { Scrollbars } from "react-custom-scrollbars";
import "./style.scss";

const { Sider } = Layout;
const SubMenu = Menu.SubMenu;
const Divider = Menu.Divider;

const mapStateToProps = ({ app, router, menu }, props) => {
  const { layoutState } = app;
  return {
    pathname: router.location.pathname,
    collapsed: layoutState.menuCollapsed,
    theme: layoutState.themeLight ? "light" : "dark",
    settingsOpened: layoutState.settingsOpened,
    menuData: menu.menuData,
    menuLoaded: menu.menuLoaded,
  };
};

@connect(mapStateToProps)
@withRouter
class MenuLeft extends React.Component {
  state = {
    pathname: this.props.pathname,
    collapsed: this.props.collapsed,
    theme: this.props.theme,
    selectedKeys: "",
    openKeys: [""],
    settingsOpened: this.props.settingsOpened,
  };

  handleClick = (e) => {
    const { dispatch, isMobile } = this.props;
    if (isMobile) {
      // collapse menu on isMobile state
      dispatch(setLayoutState({ menuMobileOpened: false }));
    }
    if (e.key === "settings") {
      // prevent click and toggle settings block on theme settings link
      dispatch(setLayoutState({ settingsOpened: !this.state.settingsOpened }));
      return;
    }
    // set current selected keys
    this.setState({
      selectedKeys: e.key,
    });
  };

  onOpenChange = (openKeys) => {
    this.setState({
      openKeys: openKeys,
    });
  };

  getPath(data, id, parents = []) {
    const { selectedKeys } = this.state;
    let items = reduce(
      data,
      (result, entry) => {
        if (result.length) {
          return result;
        } else if (entry.url === id && selectedKeys === "") {
          return [entry].concat(parents);
        } else if (entry.key === id && selectedKeys !== "") {
          return [entry].concat(parents);
        } else if (entry.children) {
          let nested = this.getPath(
            entry.children,
            id,
            [entry].concat(parents)
          );
          return nested ? nested : result;
        } else if (new RegExp(`^${entry.url}/`).test(id)) {
          return [entry].concat(parents);
        }
        return result;
      },
      []
    );
    return items.length > 0 ? items : false;
  }

  getActiveMenuItem = (props, items) => {
    this.setState((prevState) => {
      const { selectedKeys, pathname } = prevState;
      let { collapsed } = props;
      let [activeMenuItem, ...path] =
        this.getPath(items, !selectedKeys ? pathname : selectedKeys) || [];

      if (collapsed) {
        path = [""];
      }

      return {
        selectedKeys: activeMenuItem ? activeMenuItem.key : "",
        openKeys: activeMenuItem ? path.map((entry) => entry.key) : [],
        collapsed,
      };
    });
  };

  generateMenuPartitions(items) {
    return items.map((menuItem) => {
      if (menuItem.children) {
        let subMenuTitle = (
          <span className="menuLeft__title-wrap" key={menuItem.key}>
            <span className="menuLeft__item-title">{menuItem.title}</span>
            {menuItem.icon && (
              <span className={menuItem.icon + " menuLeft__icon"} />
            )}
          </span>
        );
        return (
          <SubMenu title={subMenuTitle} key={menuItem.key}>
            {this.generateMenuPartitions(menuItem.children)}
          </SubMenu>
        );
      }
      return this.generateMenuItem(menuItem);
    });
  }

  generateMenuItem(item) {
    const { key, title, url, icon, disabled } = item;
    const { dispatch, collapsed } = this.props;
    return item.divider ? (
      <Divider key={Math.random()} />
    ) : item.url ? (
      <Menu.Item key={key} disabled={disabled}>
        <Link
          to={url}
          onClick={
            this.props.isMobile
              ? () => {
                  dispatch(setLayoutState({ menuCollapsed: false }));
                }
              : undefined
          }
        >
          <span className="menuLeft__item-title">{title}</span>
          {icon && <span className={icon + " menuLeft__icon"} />}
        </Link>
      </Menu.Item>
    ) : (
      <Menu.Item key={key} disabled={disabled}>
        <span className="menuLeft__item-title">{title}</span>
        {icon && <span className={icon + " menuLeft__icon"} />}
      </Menu.Item>
    );
  }

  onCollapse = (value, type) => {
    const { dispatch } = this.props;
    const { collapsed } = this.state;
    if (type === "responsive" && collapsed) {
      return;
    }
    dispatch(setLayoutState({ menuCollapsed: !collapsed }));
  };

  componentDidMount() {
    this.getActiveMenuItem(this.props, this.props.menuData);
  }

  static getDerivedStateFromProps(props, state) {
    let selectedKeys = state.selectedKeys || "";
    if (props.pathname !== state.pathname) {
      selectedKeys = "";
    }
    return {
      selectedKeys,
      pathname: props.pathname,
      theme: props.theme,
      settingsOpened: props.settingsOpened,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { collapsed, menuLoaded } = this.props;
    const { pathname } = this.state;
    if (
      !this.props.isMobile &&
      (collapsed !== prevProps.collapsed ||
        menuLoaded !== prevProps.menuLoaded ||
        pathname !== prevState.pathname)
    ) {
      this.getActiveMenuItem(this.props, this.props.menuData);
    }
  }

  render() {
    const { collapsed, selectedKeys, openKeys, theme } = this.state;
    const { isMobile } = this.props;
    const menuItems = this.generateMenuPartitions(this.props.menuData);
    console.log("items", menuItems);

    const paramsMobile = {
      width: 256,
      collapsible: false,
      collapsed: false,
      onCollapse: this.onCollapse,
    };
    const paramsDesktop = {
      width: 256,
      collapsible: true,
      collapsed: collapsed,
      onCollapse: this.onCollapse,
      breakpoint: "lg",
    };
    const params = isMobile ? paramsMobile : paramsDesktop;
    return (
      <Sider {...params} className="menuLeft">
        <div className="menuLeft__logo">
          {params.collapsed ? (
            <div className="menuLeft__logoContainer menuLeft__logoContainer--collapsed">
              <img src="/resources/images/logo-inverse-mobile.jpg" alt="" />
            </div>
          ) : (
            <div className="menuLeft__logoContainer">
              <img src="/resources/images/logo-inverse.jpg" alt="" />
            </div>
          )}
        </div>
        <Scrollbars
          autoHide
          style={{
            height: isMobile ? "calc(100vh - 64px)" : "calc(100vh - 112px)",
          }}
        >
          <Menu
            theme={theme}
            onClick={this.handleClick}
            selectedKeys={[selectedKeys]}
            openKeys={openKeys}
            onOpenChange={this.onOpenChange}
            mode="inline"
            className="menuLeft__navigation"
          >
            {menuItems}
          </Menu>
        </Scrollbars>
      </Sider>
    );
  }
}

export { MenuLeft };
