import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { routerMiddleware, ConnectedRouter } from "connected-react-router";
import { Helmet } from "react-helmet";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory as createHistory } from "history";
import thunk from "redux-thunk";
import "es6-promise/auto";
import "setimmediate";

import { ConfigProvider, DatePicker } from "antd";
import enGB from "antd/lib/locale-provider/en_GB";
import viVN from "antd/lib/locale-provider/vi_VN";
import registerServiceWorker from "registerServiceWorker";

import Layout from "components/LayoutComponents/Layout";
import reducer from "ducks";

import "resources/_antd.less"; // redefinition AntDesign variables
import "bootstrap/dist/css/bootstrap.min.css"; // bootstrap styles

import "resources/AntStyles/AntDesign/antd.cleanui.scss";
import "resources/CleanStyles/Core/core.cleanui.scss";
import "resources/CleanStyles/Vendors/vendors.cleanui.scss";
import 'moment/locale/vi';

const { RangePicker } = DatePicker;

RangePicker.defaultProps = {
  ...RangePicker.defaultProps,
  placeholder: ["Bắt đầu", "Kết thúc"],
};

const history = createHistory();
const router = routerMiddleware(history);
const middlewares = [router, thunk];
const isLogger = false;
if (isLogger && process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}
const store = createStore(
  reducer(history),
  composeWithDevTools(applyMiddleware(...middlewares))
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ConfigProvider locale={viVN}>
        <div>
          <Helmet titleTemplate="Khang Luật - %s" />
          <Layout />
        </div>
      </ConfigProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();

export default history;
