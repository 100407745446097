import React from 'react';
import { List, Spin, Button } from 'antd';
import ToTopOutlined from "@ant-design/icons/ToTopOutlined";
import callGetNotificationList from 'api/callGetNotificationList';

import InfiniteScroll from 'react-infinite-scroller';
import NotificationItem from './NotificationItem';

class NotificationList extends React.Component {

  static cachedList = null;

  state = {
    data: [],
    loading: false,
    hasMore: true,
  };

  currPage = 0;

  componentDidMount() {
    this.setState({
      loading: true,
      data: NotificationList.cachedList || [],
    });
    this.currPage++;
    this.fetchData(this.currPage, (res) => {
      const meta = res.meta;
      this.setState({
        loading: false,
        hasMore: meta.current_page < meta.total_pages,
        data: res.items,
      }, () => {
        NotificationList.cachedList = this.state.data;
      });
    });
  }

  fetchData = (page, callback) => {
    callGetNotificationList({
      page
    }).then((res) => callback(res));
  };

  handleInfiniteOnLoad = () => {
    this.setState({
      loading: true
    });
    this.currPage++;
    const page = this.currPage;
    this.fetchData(page, (res) => {
      const meta = res.meta;
      this.setState((prevState) => ({
        loading: false,
        hasMore: meta.current_page < meta.total_pages,
        data: [
          ...prevState.data,
          ...res.items,
        ],
      }), () => {
        NotificationList.cachedList = this.state.data;
      });
    })
  };

  _onNewNotiClick = () => {
    if (this.scrollParentRef) {
      const { onNewNotiClick } = this.props;
      this.scrollParentRef.scrollTop = 0;
      this.setState({
        loading: true,
        hasMore: true,
      });
      this.currPage = 1;
      this.fetchData(this.currPage, (res) => {
        const meta = res.meta;
        this.setState({
          loading: false,
          hasMore: meta.current_page < meta.total_pages,
          data: res.items,
        }, () => {
          NotificationList.cachedList = this.state.data;
        });
      });
      onNewNotiClick && onNewNotiClick();
    }
  }

  render() {
    const { onItemClick } = this.props;
    return (
      <div className="noti-infinite-container" ref={(ref) => this.scrollParentRef = ref}>
        <InfiniteScroll
          initialLoad={false}
          pageStart={1}
          loadMore={this.handleInfiniteOnLoad}
          hasMore={!this.state.loading && this.state.hasMore}
          useWindow={false}
          getScrollParent={() => this.scrollParentRef}
        >
          <List
            dataSource={this.state.data}
            renderItem={item => (
              <NotificationItem key={item.id} item={item} onClick={onItemClick} />
            )}
          >
            {this.state.loading && this.state.hasMore && (
              <div className="text-center">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
        <div className="new-noti-button-wrapper">
          <Button type="primary" shape="round" icon={<ToTopOutlined />} onClick={this._onNewNotiClick}>
            Có thông báo mới
          </Button>
        </div>
      </div>
    );
  }
}

export default NotificationList;