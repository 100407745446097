import React from "react";
import ProfileMenu from "./ProfileMenu";
import Notification from "./Notification";
import CountDown from "components/CleanComponents/CountDown";
import CheckRoleElement from "components/UI/CheckRoleElement";

import "./style.scss";

class TopBar extends React.Component {
  render() {
    return (
      <div className="topbar">
        <div className="topbar__right">
          <CheckRoleElement route="/profile/view/:id">
            <div className="d-inline-block mr-4">
              <h4><CountDown /></h4>
            </div>
            <Notification />
          </CheckRoleElement>
          <ProfileMenu />
        </div>
      </div>
    );
  }
}

export default TopBar;
