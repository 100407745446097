import { API_DOMAIN } from "const";
export * from "./request";

export function sleep(ms: Number = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export function checkArrayInArray(checkArray: Array, targetArray: Array) {
  for (let index = 0; index < checkArray.length; index++) {
    const element = checkArray[index];
    if (targetArray.includes(element)) {
      return true;
    }
  }
  return false;
}

export function buildFileURL(url) {
  const builtURL = `${API_DOMAIN}/${url}`;
  return builtURL;
}

export function getAvatarURL(userInfo, version) {
  if (!userInfo) {
    return null;
  }
  return buildFileURL(`files/images/avatar/${userInfo.id}?v=${version}`);
}

export const unsignedString = (string) => {
  const signedChars =
    'àảãáạăằẳẵắặâầẩẫấậđèẻẽéẹêềểễếệìỉĩíịòỏõóọôồổỗốộơờởỡớợùủũúụưừửữứựỳỷỹýỵÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬĐÈẺẼÉẸÊỀỂỄẾỆÌỈĨÍỊÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢÙỦŨÚỤƯỪỬỮỨỰỲỶỸÝỴ';
  const unsignedChars =
    'aaaaaaaaaaaaaaaaadeeeeeeeeeeeiiiiiooooooooooooooooouuuuuuuuuuuyyyyyAAAAAAAAAAAAAAAAADEEEEEEEEEEEIIIIIOOOOOOOOOOOOOOOOOUUUUUUUUUUUYYYYY';
  const pattern = new RegExp(`[${signedChars}]`, 'g');
  const output = string.replace(pattern, (m, key, value) =>
    unsignedChars.charAt(signedChars.indexOf(m)),
  );
  return output;
};