import { sleep, request } from "utils";

export type UserInfoType = {
  id: String,
  name: String,
  address: String,
  identify_card: String,
  is_quit: Number,
  is_root: Number,
  phone: String,
  identification: String,
  role: String,
  avatar: String,
};

export async function callGetUserInfo(): Promise<UserInfoType> {
  const res = await request.get("/employee/detail");
  return res;
}

export default callGetUserInfo;
