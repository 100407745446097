import { createReducer } from "redux-act";
import * as app from "./app";

export const REDUCER = "login";

type LoginInfoType = { username: string, password: string };

export const submit = ({ username, password }: LoginInfoType) => async (
  dispatch: Function,
  getState: Function
) => {
  dispatch(app.addSubmitForm(REDUCER));

  let isLoggined = await app.login(username, password, dispatch);

  if (isLoggined) {
    dispatch(app.deleteSubmitForm(REDUCER));
  } else {
    dispatch(app.deleteSubmitForm(REDUCER));
  }
};

const initialState = {};
export default createReducer({}, initialState);
