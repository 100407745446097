import { createReducer, createAction } from "redux-act";
import callGetMenus from "api/callGetMenus";
import { checkArrayInArray } from 'utils';

export const REDUCER = "menu";
const NS = `@@${REDUCER}/`;

export const _setMenuLoaded = createAction(`${NS}SET_MENU_LOADED`);
export const _setMenuData = createAction(`${NS}SET_MENU_DATA`);
export const _setRoleMenuData = createAction(`${NS}SET_ROLE_MENU_DATA`);
export const _setCheckedRolesData = createAction(`${NS}SET_CHECKED_ROLES_DATA`);

const recursiveProcessMenuData = (role, menuData, parentMenu = null) => {
  const menus = [];
  const roleMenus = [];
  let checkedRoles = {};
  menuData.forEach((menu) => {
    const item = { ...menu };
    if (parentMenu) {
      item.parent = parentMenu;
    }
    roleMenus.push(item);
    if (item.children) {
      const [
        tmpMenus,
        tmpRoleMenus,
        tmpCheckedRoles,
      ] = recursiveProcessMenuData(role, menu.children, menu);
      item.children = tmpMenus;
      if (item.children.length === 0) {
        delete item.children;
      }
      roleMenus.push(...tmpRoleMenus);
      checkedRoles = {
        ...checkedRoles,
        ...tmpCheckedRoles,  
      };
    }
    if (!item.roles || checkArrayInArray(role, item.roles)) {
      checkedRoles[item.url] = item;
      if (item.title) {
        menus.push(item);
      }
    }
  });
  return [menus, roleMenus, checkedRoles];
};

export const loadMenuData = () => async (dispatch, getState) => {
  const {
    menu,
    app: { userState },
  } = getState();
  if (menu.menuLoaded) {
    return [menu.menuData, menu.roleMenuData];
  }
  try {
    dispatch(_setMenuLoaded(false));
    const menuData = await callGetMenus();
    const [menus, roleMenus, checkedRoles] = recursiveProcessMenuData(
      userState.role,
      menuData
    );
    dispatch(_setMenuData(menus));
    dispatch(_setRoleMenuData(roleMenus));
    dispatch(_setCheckedRolesData(checkedRoles));
    dispatch(_setMenuLoaded(true));
    return [menuData, roleMenus];
  } catch (e) {
    console.log(e);
    dispatch(_setMenuLoaded(false));
    dispatch(_setMenuData([]));
    return null;
  }
};

const initialState = {
  menuLoaded: false,
  menuData: [],
  roleMenuData: [],
  checkedRolesData: {},
};
export default createReducer(
  {
    [_setMenuLoaded]: (state, menuLoaded) => ({ ...state, menuLoaded }),
    [_setMenuData]: (state, menuData) => ({ ...state, menuData }),
    [_setRoleMenuData]: (state, roleMenuData) => ({ ...state, roleMenuData }),
    [_setCheckedRolesData]: (state, checkedRolesData) => ({
      ...state,
      checkedRolesData,
    }),
  },
  initialState
);
