import { combineReducers } from "redux";
import { pendingTasksReducer } from "react-redux-spinner";
import app from "./app";
import login from "./login";
import menu from "./menu";
import router from "./router";

export default (history) =>
  combineReducers({
    router: router(history),
    pendingTasks: pendingTasksReducer,
    app,
    login,
    menu,
  });
