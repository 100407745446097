import { LocationState } from "history";
import { push, connectRouter } from "connected-react-router";
export * from "connected-react-router";

export const reservedPush = (path: string, state?: LocationState) => (
  dispatch,
  getState
) => {
  const { router } = getState();

  const LocationDescriptor = {
    ...router.location,
    pathname: path,
  };

  if (state !== undefined) {
    LocationDescriptor.state = state;
  }

  dispatch(push(LocationDescriptor));
};

export const concatReservedPush = (subPath: string, state?: LocationState) => (
  dispatch,
  getState
) => {
  const {
    router: { location },
  } = getState();
  dispatch(reservedPush(`${location.pathname}${subPath}`, state));
};

export default connectRouter;
